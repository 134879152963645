import React from 'react';
import Language from '@Components/Language';
import footerLogo from '@Assets/images/footer-logo.svg';

export default function AppFooter() {
  return (
    <div className="layout-footer flex justify-content-between p-4 shadow-2">
      <div>
        {Language.get('The JMS platform is brought to you by')} <img src={footerLogo} alt="footer-logo" />
      </div>
      <span>&copy;{new Date().getFullYear()}. {Language.get('All rights reserved  |  Privacy Policy  |  EULA')}</span>
    </div>
  );
}
